.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.insta{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Scada', sans-serif;
}

.insta img{
    width: 36px;
    height: 36px;
}


.copyArea{
    border: 1px solid #ee46a9;
    border-radius: 4px;
    margin: 6px 6px 14px 6px;
}

.copyArea > div{
    padding: 6px;
}

.closeBtn{
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;

}