.container{
    background-color: #ee46a9;
    padding: 10px 14px 10px 14px;
    text-decoration: none;
    /*color: #ee46a9;*/
    font-family: 'Scada', sans-serif;
    font-size: 18px;
    border-radius: 2px;
    -moz-user-select: none;
    /*margin-bottom: 12px;*/
    /*margin-top: 10px;*/
    cursor: pointer;
    text-align: center;
}

.container:hover{
    background-color: rgb(255, 94, 188);
    color: #090f2a;
}