hr{
    width: 100px;
    color: #ee46a9;
}

input[type=text]{
    width: 32px;
    text-align: center;
    padding: 8px;
    margin: auto;
    display: inline-block;
    border: none;
    background: #d1d3d7;
    border-radius: 4px;
}

/* Добавляет затемнение при заполнении формы*/
input[type=text]:focus{
    background-color: #e589c2;
    outline: none;
}

input[type=radio]{
    transform: scale(1.3);
    margin: 0 4px 0 20px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    color: #d2d2d2;
}


.title{
    text-align: center;
    font-size: 18px;
    margin-bottom: 18px;
    font-family: 'Neucha', cursive;
    color: #ee46a9
}

.questionBox, .ageQuestionBox, .deathQuestionBox{
    background-color: #00001d;
    width: 30vw;
    padding: 12px;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
}

.deathQuestionBox input[type=text] {
    width: 90px;
}

.questionBox p, .ageQuestionBox p, .deathQuestionBox p{
    padding: 4px;
    text-align: center;
}

.image{
    border: 4px  solid #ee46a9;
    border-radius: 4px;
}

.insta{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Scada', sans-serif;
}

.insta img{
    width: 30px;
    height: 30px;
}


.copyArea{
    border: 1px solid #ee46a9;
    border-radius: 4px;
    margin: 6px 6px 14px 6px;
}

.copyArea > div{
    padding: 6px;
}

.closeBtn{
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;

}

.test{
    position: relative;
}



@media (max-width: 800px) {
    .questionBox, .ageQuestionBox, .deathQuestionBox{
        width: 90vw;
    }

    .title{
        font-size: 16px;
    }
}

@media (min-width: 800px) and (max-width: 1400px){
    .questionBox, .ageQuestionBox, .deathQuestionBox{
        width: 70vw;
    }

}