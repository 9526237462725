.container{
    display: none;
    margin-top: 20px;
    /*margin-bottom: 20px;*/
    position: relative;
}

.title{
    text-align: center;
    font-size: 18px;
    margin: 10px 0 16px 0;
    font-family: 'Neucha', cursive;
    color: #ee46a9;
}

.image{
    border: 4px  solid #ee46a9;
    border-radius: 4px;
}

.active{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn:hover{
    cursor: pointer;
}



@media (max-width: 800px) {
    .questionBox{
        width: 90vw;
    }

    .title{
        font-size: 16px;
    }
}

@media (min-width: 800px) and (max-width: 1400px){
    .questionBox{
        width: 70vw;
    }

}