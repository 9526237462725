.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.8);

}

.modalContent{
    padding: 20px;
    background-color: #090f2a;
    border-radius: 4px;
    max-width: 95vw;
    position: relative;
}

.modal.active{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-height: 700px) {
    .modalContent{
        font-size: 12px;
    }
}

@media (min-height: 700px) and (max-height: 900px) {
    .modalContent{
        font-size: 14px;
    }
}