.Main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.infocards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 13vw 0 13vw;
}

.testcards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.mainTitle{
    text-align: center;
    font-size: 26px;
    width: 100vw;
    margin-bottom: 40px;
    font-family: 'Neucha', cursive;
    color: #ee46a9;
}


@media (max-width: 1200px) {
    .Main{
        justify-content: start;
        margin-top: 16px;
    }

    .infocards{
        margin: 0;
    }
}