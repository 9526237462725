/*------------- Стиль радио-кнопок -------------------*/

.form_radio_btn {
    display: inline-block;
    margin-right: 0px;
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    padding: 2px 10px;
    line-height: 34px;
    border: 2px solid #d2d2d2;
    border-radius: 12px;
    user-select: none;
    color: #d2d2d2;
    font-family: 'Scada', sans-serif;
}

.form_radio_btn input[type=radio]:checked + label {
    /*background: #999;*/
    border: 2px solid #ee46a9;
    color: #ee46a9;
}

.form_radio_btn label:hover {
    color: #e589c2;
    border: 2px solid #e589c2;
}