.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #00001d;
    width: 320px;
    height: 140px;
    margin: 10px;
    border-radius: 6px;
    text-decoration: none;
    /*border: 2px solid black;*/

}
.container:hover{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.8);
    font-size: 16.2px;
}

.title{
    padding: 16px 0 0 0;
    font-family: 'Comfortaa', cursive;
    color: #ee46a9;
    text-align: center;
}

.description{
    color: #d2d2d2;
    font-family: 'Overpass', sans-serif;
    text-align: center;
    padding: 6px;
    display: flex;
    height: 100%;
    align-items: center;
}
