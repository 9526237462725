.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    color: #d2d2d2;

}

.title{
    text-align: center;
    font-size: 22px;
    width: 100vw;
    margin-bottom: 40px;
    font-family: 'Neucha', cursive;
    color: #ee46a9;
}

.box{
    background-color: #00001d;
    width: 40vw;
    padding: 20px;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 20px;
}

.box ul{
    padding: 20px;
}

.insta{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Scada', sans-serif;
    margin-bottom: 20px;
}


@media (max-width: 800px) {
    .box{
        width: 90vw;
    }

    .container{
        justify-content: start;
        margin-top: 30px;
        height: unset;
    }
}

@media (min-width: 800px) and (max-width: 1400px){
    .box{
        width: 70vw;
    }

    .container{
        justify-content: start;
        height: unset;
        margin-top: 30px;
    }
}