.questionBox hr{
    width: 200px;
    margin: 10px 0 16px 0;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    margin-top: 20px;
    margin-bottom: 20px;
    /*position: relative;*/
    color: white;
}

.title{
    text-align: center;
    font-size: 18px;
    margin-bottom: 18px;
    font-family: 'Neucha', cursive;
    color: #ee46a9;
}

.questionBox{
    background-color: #00001d;
    width: 30vw;
    padding: 12px;
    font-family: 'Scada', sans-serif;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
}

.checkBox{
    display: none;
}

.checkBoxContainer{
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}

.checkBoxContainer .checkBox:checked + label{
    color: #ee46a9;
}

.checkBoxContainer label:hover{
    color: #e589c2;
    cursor: pointer;
}

.insta{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Scada', sans-serif;
}

.insta img{
    width: 36px;
    height: 36px;
}


.copyArea{
    border: 1px solid #ee46a9;
    border-radius: 4px;
    margin: 6px 6px 14px 6px;
}

.copyArea > div{
    padding: 6px;
}

.closeBtn{
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;

}
@media (max-width: 800px) {
    .questionBox{
        width: 90vw;
    }

    .title{
        font-size: 16px;
    }
}

@media (min-width: 800px) and (max-width: 1400px){
    .questionBox{
        width: 70vw;
    }

}