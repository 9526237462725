.questionBox{
    background-color: #00001d;
    width: 30vw;
    padding: 12px;
    font-family: 'Overpass', sans-serif;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
}


@media (max-width: 800px) {
    .questionBox{
        width: 90vw;
    }

    .title{
        font-size: 16px;
    }
}

@media (min-width: 800px) and (max-width: 1400px){
    .questionBox{
        width: 50vw;
    }

}